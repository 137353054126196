<template>
  <div class="w-full h-screen flex">
    <div class="w-full md:w-1/2 bg-gray-100">
      <div class="container flex flex-col w-2/3 mt-20">
        <router-link to="/">
          <img
            src="@/_assets/images/hive_logo.svg"
            class="w-48 h-16 object-contain"
          />
        </router-link>
        <h1 class="font-bold text-2xl text-darkblue-800 mt-12">
          Apri gratis il tuo negozio
        </h1>
        <h3 class="py-2 font-medium">
          Configura <strong>HIVE</strong> in 60 secondi
        </h3>
        <span class="py-6 text-secondary-500 flex">
          <popper
            trigger="clickToOpen"
            :options="{
              placement: 'left',
              modifiers: { offset: { offset: '0,10px' } }
            }"
          >
            <div
              class="w-64 absolute bg-white px-4 py-8 z-50 shadow-xl rounded-sm"
            >
              <h1>Step 1</h1>
              <p class="text-gray-700">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad
                corrupti illum maiores iure, saepe soluta libero eum aperiam
                eveniet magnam tenetur laborum voluptate placeat accusantium nam
                aliquid eius hic blanditiis!
              </p>
            </div>

            <button slot="reference">
              <button
                class=" bg-secondary-200 flex items-baseline justify-center w-6 h-6 rounded-full mr-2"
              >
                i
              </button>
            </button>
          </popper>

          <h6>Come aprire un negozio?</h6>
        </span>
        <progress
          class="w-full h-2"
          max="6"
          :value="activeStepNumber"
        ></progress>
        <transition name="step">
          <router-view
            :key="activeStepNumber"
            :to="activeStep"
            @nextstep="activeStepNumber++"
            @prevstep="activeStepNumber--"
            @sendSms="sendSms"
          ></router-view>
          <!-- <component
            :is="activeStep"
            @nextstep="activeStepNumber++"
            @createstore="createStore"
            @prevstep="activeStepNumber--"
          ></component> -->
        </transition>
        <!-- <input
          type="tel"
          v-model="phonenumber"
          placeholder="phone"
          pattern="/(3)\d{9}/"
        />
        <button class="btn btn-cta" @click="signin">Firebase</button>
        <input type="text" v-model="otp" placeholder="OTP" />
        <button class="btn btn-cta" @click="confirmOtp">OTP</button> -->
        <div id="recaptcha"></div>
      </div>
    </div>
    <div
      class="hidden md:w-1/2 bg-darkblue-500 text-white md:flex justify-center items-center"
    >
      <img src="@/_assets/images/pay_img.png" class="" />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
import firebase from "firebase";
import "firebase/auth";

import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

// import AWS from "aws-sdk";
// const awsV4 = require("aws-v4");

// import Radio from "@/_components/Radio";
// import Step1 from "./_components/Step1";

// TODO: Customize errors for each input fields
export default {
  components: { Popper },
  name: "CreateStore",
  data() {
    return {
      activeStepNumber: this.$route.path.slice(-1) || 1,
      //phone: "+390000000007",
      otp: "123456",
      uid: null,
      user: null,
      recaptchaVerifier: null
    };
  },
  mounted() {
    //Start Firebase invisible reCAPTCHA verifier
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha", {
      size: "invisible",
      callback: () => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.sendSMS();
      }
    });
    //
  },
  computed: {
    ...mapState("createstore", [
      "storeType",
      "storeName",
      "name",
      "surname",
      "email",
      "password"
    ]),
    ...mapGetters("createstore", ["phonenumber"]),
    activeStep() {
      return `/${this.activeStepNumber}`;
    }
  },
  watch: {
    activeStepNumber(v) {
      this.$router.push(`${v}`);
    }
  },
  methods: {
    ...mapActions("createstore", ["loginWithPhone"]),

    async sendSms() {
      try {
        console.log("App verifier", this.recaptchaVerifier);
        let done = await this.loginWithPhone(this.recaptchaVerifier);

        console.log("TUTTO OK", done);
        this.activeStepNumber++;
      } catch (err) {
        console.log("Errore login ", err);
      } finally {
        console.log("FINITO");
      }
    },
    async createStore() {
      try {
        let formData = {
          storeType: this.storeType,
          storeName: this.storeName,
          name: this.name,
          surname: this.surname,
          email: this.email,
          password: this.password
        };
        let post = await Axios({
          method: "POST",
          url: "https://run.mocky.io/v3/40613663-bbe8-4e40-be54-3da8aae14170",
          headers: {
            "Content-Type": "application/json"
          },
          data: formData
        });
        if (post.status == 200) {
          //this.$router.push("/");
          this.activeStepNumber++;
        }
        console.log("Invio il form", formData, post);
      } catch (error) {
        console.log("Error", error);
      }
    },
    updateForm(obj) {
      this.formData = { ...obj };
      this.activeStepNumber++;
    }
  }
};
</script>

<style lang="postcss">
progress {
  -webkit-appearance: progress-bar;
  appearance: progress-bar;
}
progress::-webkit-progress-bar {
  background: theme("colors.gray.300");

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.125) inset;
}
progress::-webkit-progress-value {
  //background: theme("colors.secondary.500");
  @apply bg-gradient-to-r from-secondary-300 to-secondary-500;
  transition: width 0.5s cubic-bezier(0.75, -0.5, 0, 1.75);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.125) inset;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  @apply absolute top-0 left-0 w-6 h-6 border border-gray-300 bg-white rounded-full;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  @apply w-6 h-6 bg-secondary-500 absolute rounded-full top-0 left-0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.step-enter-active {
  transition: all 0.3s ease;
}
.step-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.step-enter, .step-leave-to
/* .open-leave-active below version 2.1.8 */ {
  transform: translatey(-25%);
  opacity: 0;
}
</style>
